<template>
  <v-form ref="filterForm" @submit.prevent="submitFilter()">
    <v-row dense>
      <v-col cols="12" md="2">
        <input-date v-model="filters.startTime" :label="$t('startTime')" :rules="[requiredRule, dateLength]" dense />
      </v-col>
      <v-col cols="12" md="2">
        <input-date
          v-model="filters.finishTime"
          :label="$t('finishTime')"
          :rules="[
            requiredRule,
            dateLength,
            dataIntervalMustBeLessThanDays(filters.startTime, filters.finishTime, searchDateLimitDays),
          ]"
          dense
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="filters.companyCode"
          :items="companyCodeList"
          :label="filtroEmpresa"
          :rules="[requiredRule]"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model="filters.cteKey" :label="$t('cteKey')" outlined dense clearable />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model="filters.compNumber"
          :rules="[length(filters.compNumber, 10)]"
          :label="$t('compNumber')"
          outlined
          dense
          clearable
        />
      </v-col>
      <v-col cols="12" md="5">
        <v-autocomplete
          v-model="filters.cteNumber"
          :label="$t('cteNumber')"
          :items="filters.cteNumber"
          @paste.prevent="pasteData($event, 'cteNumber')"
          @keydown.space="pushManualValue($event, 'cteNumber')"
          class="multipleDocs"
          @click:clear="filters.cteNumber = []"
          outlined
          clearable
          multiple
          chips
          deletable-chips
          small-chips
          :allow-overflow="false"
          :no-data-text="'Informe os CTe\'s'"
          dense
          :append-icon="''"
          menu-props="closeOnContentClick"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="filters.docNumber"
          :label="$t('docNumber')"
          :items="filters.docNumber"
          @paste.prevent="pasteData($event, 'docNumber')"
          @keydown.space="pushManualValue($event, 'docNumber')"
          class="multipleDocs"
          @click:clear="filters.docNumber = []"
          outlined
          clearable
          multiple
          chips
          deletable-chips
          small-chips
          :allow-overflow="false"
          :no-data-text="'Informe os documentos de transporte'"
          dense
          :append-icon="''"
          menu-props="closeOnContentClick"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="5">
            <v-btn depressed color="#D8D8D8" :block="$vuetify.breakpoint.xsOnly" type="submit">
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <TLoading v-model="loading" />
  </v-form>
</template>

<script>
import mixinFilters from '@/mixins/mixinFilter'
import mixinRules from '@/mixins/mixinRules'
import InputDate from '@/components/InputDate.vue'

export default {
  name: 'FilterComponent',
  mixins: [mixinFilters, mixinRules],
  components: {
    InputDate,
  },
  data() {
    return {
      path: '',
      searchDateLimitDays: 30,
      filters: {
        startTime: '',
        finishTime: '',
        companyCode: '',
        docNumber: [],
        cteNumber: [],
        cteKey: '',
        compNumber: '',
      },
    }
  },
  methods: {
    prepareFilter() {
      const filter = {}
      this.verifyNullableFilter(filter, 'startTime', `${this.filters.startTime}T00:00:00-03:00`)
      this.verifyNullableFilter(filter, 'finishTime', `${this.filters.finishTime}T23:59:59-03:00`)
      this.verifyNullableFilter(filter, 'companyCode', this.filters.companyCode)
      this.verifyNullableFilter(filter, 'docNumber', this.filters.docNumber)

      this.verifyNullableFilter(filter, 'cteKey', this.filters.cteKey)
      this.verifyNullableFilter(filter, 'compNumber', this.filters.compNumber)
      this.verifyNullableFilter(filter, 'cteNumber', this.filters.cteNumber)
      return filter
    },
    findKey(object, key, value) {
      Object.keys(object).some(function (k) {
        if (k === key) {
          if (typeof object[k] == 'boolean') {
            object[k] = JSON.parse(value)
          } else {
            object[k] = value
          }
          return true
        }
      })
    },
  },
}
</script>
