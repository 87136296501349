<script>
import FilterComponent from './components/TransportationDocumentFilterComponent.vue'

export default {
  extends: FilterComponent,
  name: 'TransportationDocumentFiltersComponent',
  data() {
    return {
      path: 'transportationDocument',
      searchDateLimitDays: 7,
    }
  },
}
</script>
